import _ from 'lodash';
import type { GetServerSidePropsContext } from 'next';

import { Container } from 'styles/pages/Home';

import { ERROR_URL } from 'libs/constants';
import { EEventStatusValues } from 'libs/enums';
import { eventList, featuredEventList } from 'libs/requests';
import {
  IEventListEvent,
  IFeaturedEventListEvent,
  PaginatedList
} from 'libs/types';

import {
  Footer,
  Testimonials,
  EventGrid,
  HomeCarousel,
  Navigation,
  Head
} from 'components';

interface IProps {
  featuredEvents: Array<IFeaturedEventListEvent>;
  upcomingEventsData: PaginatedList<IEventListEvent>;
  liveEventsData: PaginatedList<IEventListEvent>;
  previousEventsData: PaginatedList<IEventListEvent>;
}

const Home = ({
  featuredEvents,
  upcomingEventsData,
  liveEventsData,
  previousEventsData
}: IProps) => (
  <>
    <Head
      title="Explore global real estate events."
      description="Explore and experience global real estate events."
      image="/images/home_og_image.png"
    />

    <Container>
      <Navigation withNotifications={false} />
      <HomeCarousel events={featuredEvents} />
      {/* This should be darker if there aren't any live events as that would prevent the previous section from displaying. */}
      <EventGrid
        title="Live"
        withDarkerBackground
        maximumColumnCount={3}
        nextUrl={liveEventsData.next}
        events={liveEventsData.results}
        eventCount={liveEventsData.count}
      />
      <EventGrid
        title="Upcoming"
        withDarkerBackground={_.isEmpty(liveEventsData.results)}
        maximumColumnCount={3}
        nextUrl={upcomingEventsData.next}
        events={upcomingEventsData.results}
        eventCount={upcomingEventsData.count}
      />
      <Testimonials />
      <EventGrid
        title="Previous"
        maximumColumnCount={4}
        withReducedEventBoxSize
        nextUrl={previousEventsData.next}
        events={previousEventsData.results}
        eventCount={previousEventsData.count}
      />
    </Container>
    <Footer />
  </>
);

export const getServerSideProps = async (
  context: GetServerSidePropsContext
) => {
  const [
    {
      data: featuredEventsData,
      success: featuredEventsSuccess,
      error: featuredEventsError
    },
    {
      data: upcomingEventsData,
      success: upcomingEventsSuccess,
      error: upcomingEventsError
    },
    {
      data: liveEventsData,
      success: liveEventsSuccess,
      error: liveEventsError
    },
    {
      data: previousEventsData,
      success: previousEventsSuccess,
      error: previousEventsError
    }
  ] = await Promise.all([
    featuredEventList({ context }),
    eventList({
      context,
      params: { status: EEventStatusValues.UPCOMING, order: 'start', limit: 6 }
    }),
    eventList({
      context,
      params: { status: EEventStatusValues.LIVE, order: 'start', limit: 3 }
    }),
    eventList({
      context,
      params: { status: EEventStatusValues.PREVIOUS, order: '-start', limit: 4 } // the limit is different on purpose
    })
  ]);

  if (
    featuredEventsData &&
    featuredEventsSuccess &&
    upcomingEventsData &&
    upcomingEventsSuccess &&
    liveEventsData &&
    liveEventsSuccess &&
    previousEventsData &&
    previousEventsSuccess
  ) {
    return {
      props: {
        featuredEvents: featuredEventsData.results,
        upcomingEventsData,
        liveEventsData,
        previousEventsData
      }
    };
  } else if (
    featuredEventsError.status === 404 ||
    upcomingEventsError.status === 404 ||
    liveEventsError.status === 404 ||
    previousEventsError.status === 404
  ) {
    return { notFound: true };
  } else {
    return { redirect: { destination: ERROR_URL, permanent: false } };
  }
};

export default Home;
